<template>
  <form class="login-form" @submit.prevent="login">
    <PassportInput
      ref="firstInputLogin"
      type="email"
      v-model="email"
      placeholder="Email"
      required
      autocomplete="email"
    />
    <PassportInput
      type="password"
      class="password"
      v-model="password"
      placeholder="Password"
      required
      autocomplete="current-password"
    />
    <PassportButton :disabled="loading" class="submit" type="submit">Sign In</PassportButton>
  </form>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue';
import PassportInput from '@/components/PassportInput.vue';
import { ApiService } from '@/services/api.service';

export default {
  name: 'login-form',
  components: {
    PassportInput,
    PassportButton,
  },
  data() {
    return {
      loading: false,
      loader: null,
      email: null,
      password: null,
      forgotPassSent: false,
    };
  },
  props: {
    isVerificationEmailSent: Boolean,
  },
  beforeDestroy() {
    if (this.loader) {
      this.loader.hide();
    }
  },
  computed: {},
  methods: {
    async login(event) {
      this.loading = true;
      this.loader = this.$loading.show();
      const profile = {
        email: event.srcElement[0].value,
        password: event.srcElement[1].value,
      };
      try {
        const { token } = (await ApiService.post('/user/auth', profile)).data;
        if (token) {
          this.parseJwt(token);
          if (this.parseJwt(token).admin) {
            this.$store.commit('setJwt', token);
            this.$router.push('/users');
          } else {
            this.$router.push('/');
            this.$notify({
              group: 'primary',
              type: 'error',
              text: 'Login failed. Email or password provided is incorrect.',
            });
          }
        }
        this.loader.hide();
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.loader.hide();
        this.$notify({
          group: 'primary',
          clean: true,
        });
        this.$notify({
          group: 'primary',
          type: 'error',
          text: 'Login failed. Email or password provided is incorrect.',
        });
      }
    },
    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join(''),
      );
      return JSON.parse(jsonPayload);
    },
    logout() {
      this.$loading.show();
      this.$store.dispatch('logout').then(() => window.location.reload());
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  align-items: center;
  .passport-input {
    width: 100%;
  }

  .submit {
    margin: 35px auto 0 auto !important;
  }
}
</style>
