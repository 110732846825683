<template>
  <nav aria-label="primary" class="g-side-nav">
    <!-- <AppUserWelcome v-if="$mq !== 'lg'" /> -->
    <div class="logo-container" @click="$router.push('/users')">
      <DigitalPassport class="digital-logo" />
      <!-- <span class="dashboard-text">Dashboard</span> -->
    </div>
    <ul class="main-nav">
      <!-- @slot Renders inside an ul so expects list of </li>'s -->
      <slot name="mainNav">
        <li>
          <router-link to="/users" exact class="route">
            <UsersInactive class="icon inactive" />
            <UsersActive class="icon active" />
            <span>Users</span>
          </router-link>
        </li>
        <li :class="{ 'is-open': isOpen, 'is-close': !isOpen }" @click="isOpen = !isOpen">
          <!-- <router-link to="/stamps" class="route"> -->
          <a class="route" href="#" style="margin-top: 0">
            <StampsInactive class="icon inactive" />
            <StampsActive class="icon active" />
            <span :class="{ 'stamps-heading': isOpen }">Stamps</span>
            <Carat class="carat" />
            <!-- </router-link> -->
          </a>
        </li>
        <template v-if="isOpen">
          <li>
            <router-link to="/targets" class="route">
              <TargetsInactive class="icon inactive" />
              <TargetsActive class="icon active" />
              <span class="sub-category">Groups & Targets</span>
            </router-link>
          </li>
          <li>
            <router-link to="/quizzes" class="route">
              <QuizzesInactive class="icon inactive" />
              <QuizzesActive class="icon active" />
              <span class="sub-category">Trivia & Quizzes</span>
            </router-link>
          </li>
        </template>
        <li>
          <router-link to="/analytics" class="route">
            <AnalyticsInactive class="icon inactive" />
            <AnalyticsActive class="icon active" />
            <span>Analytics</span>
          </router-link>
        </li>
      </slot>
    </ul>
    <PassportButton class="logout" variant="text" label="Logout" @click="logout" />
  </nav>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue';

import DigitalPassport from '@/assets/ford/bronco-logo.svg';
import UsersInactive from '@/assets/icons/users_inactive.svg';
import UsersActive from '@/assets/icons/users_active.svg';
import StampsInactive from '@/assets/icons/stamps_inactive.svg';
import StampsActive from '@/assets/icons/stamps_active.svg';
import Carat from '@/assets/icons/carat.svg';
import TargetsInactive from '@/assets/icons/targets_inactive.svg';
import TargetsActive from '@/assets/icons/targets_active.svg';
import QuizzesInactive from '@/assets/icons/quizzes_inactive.svg';
import QuizzesActive from '@/assets/icons/quizzes_active.svg';
import AnalyticsInactive from '@/assets/icons/analytics_inactive.svg';
import AnalyticsActive from '@/assets/icons/analytics_active.svg';
import { ApiService } from '@/services/api.service';

export default {
  name: 'PassportSideNav',
  components: {
    PassportButton,
    Carat,
    DigitalPassport,
    UsersInactive,
    UsersActive,
    StampsInactive,
    StampsActive,
    TargetsInactive,
    TargetsActive,
    QuizzesInactive,
    QuizzesActive,
    AnalyticsInactive,
    AnalyticsActive,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    logout() {
      ApiService.post('/user/logout').then(() => {
        this.$loading.show();
        this.$store.commit('setJwt', '');
        this.$router.push('/login');
      });
      // window.location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
.g-side-nav {
  position: fixed;
  z-index: 6;
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 100%;
  font-family: $head-font-stack;
  font-size: 10px;
  color: black;
  // background-image: url(../assets/images/bg_nav@2x.png);
  // background-size: cover;
  background-color: #243a53;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  .main-nav {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-items: flex-start;
    justify-content: space-between;
    min-height: auto;
    padding: 0;
    padding-bottom: 27px;
    margin: 0;
    font-size: 1.8em;
    font-weight: 300;
    color: $primary-white;
    list-style-type: none;
    li {
      flex: 1 1 20%;
      width: 100%;
    }
    .stamps-heading {
      font-weight: bold;
    }

    .route {
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0 20px;
      margin-top: 20px;
      margin-right: 10px;
      margin-left: 10px;
      line-height: 1.2;
      color: inherit;
      text-align: center;
      text-align: left;
      text-decoration: none;

      &:hover {
        background-color: #ffffff26;
        border-radius: 10px;
      }
      &.router-link-active {
        font-weight: bold;
        background-color: #ffffff26;
        border-radius: 10px;
        .icon {
          &.inactive {
            display: none;
            opacity: 0;
          }
          &.active {
            display: inline-block;
            opacity: 1;
          }
          path {
            stroke-width: 0;
          }
        }
      }
    }
    .sub-category {
      font-size: 14px;
    }

    .icon {
      display: inline-block;
      width: 30px;
      height: 24px;
      margin-right: 15px;
      vertical-align: middle;
      path,
      rect {
        fill: currentColor;
      }
      &.active {
        display: none;
      }
    }
  }
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4em;
}
.main-logo {
  width: 3.875em;
  height: 1.875em;
}
.digital-logo {
  width: 14.5em;
  margin-top: 3em;
  margin-bottom: 6em;
}
.dashboard-text {
  margin-bottom: 2em;
  font-size: 1.3em;
  font-weight: bold;
  color: $primary-white;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.carat {
  width: 10px;
  height: 50px;
  margin-left: 3em;
  ::v-deep path {
    stroke: $primary-white;
  }
}
li.is-open {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}
li.is-close {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}
li.is-open .carat {
  transform: rotate(180deg);
}
.logout {
  position: absolute;
  bottom: 1em;
  left: 1em;
  padding: 10px;
  font-size: 14px;
  color: $primary-white;
  text-decoration: none;
}
</style>
